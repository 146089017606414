const ENVIRONMENT = {
  development: {
    url: 'http://127.0.0.1:8000',
  },
  homologation: {
    url: 'https://hom.robomindmkt.asaas.leaderaplicativos.com.br',
  },
  production: {
    url: 'https://robomindmkt.asaas.leaderaplicativos.com.br',
  },
};

export const API_URL = ENVIRONMENT.homologation.url;

export const TITLE = 'Robomind Mktp';

export const RESPONSIVE_VALUE = '900px';

const API_KEY_PAGARME_PROD = 'ak_live_AChJyOe9jUbTe42Fw4BjK9ZODHCEjO';

const API_KEY_PAGARME_DEV = 'ak_test_txpgj3ghEPqEQ7eQO1CqIy7wb2Hdqy';

export const API_KEY: string =
  process.env.NODE_ENV === 'production'
    ? API_KEY_PAGARME_PROD
    : API_KEY_PAGARME_DEV;

export const urlToDownloadTheFile = `${API_URL}/api/v1/subscriber/bank_slip_carnet`;

